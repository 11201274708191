import React from 'react';
import '../App.css';
import '../style/Dashboard.css';
import {connect} from "react-redux";
import primaryValues from '../constants/Values.js';
import Center from '../components/Center.js';
import ModalApp from '../components/ModalApp';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
    }

    this.props.fetchUserToken({type: primaryValues.$GET_TOKEN, params: {
        token: this.props.initialState.user_token,
    }});
  }

  closeModal = () => {
    this.setState({modalActive: false});
    this.props.initialState.error_msg = '';
  }

  render() {
    if (this.props.initialState.token_success)
      return (
          <div className='App'>
            {this.props.initialState.newImageModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Image..'} /> : null}
            {this.props.initialState.newClubModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Club..'} /> : null}
            {this.props.initialState.newUserModal === true ?
                <ModalApp name={'loading'} title_name={'Add New User..'} /> : null}
            {this.props.initialState.newOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Order..'} /> : null}
            {this.props.initialState.deleteOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Delete Order..'} /> : null}
            {this.props.initialState.updateOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Update Order..'} /> : null}
            {this.props.initialState.sendMessageModal === true ?
                <ModalApp name={'loading'} title_name={'Send Order..'} /> : null}
            {this.props.initialState.sendVaucherToCustomer === true ?
                <ModalApp name={'loading'} title_name={'Send Vaucher To Customer..'} /> : null}
            <ModalApp title_name={this.props.initialState.error_msg} reset={this.closeModal} />
            <div className='tab-app'>
              <div className='tab-view'>
                <div className='tab-view-inside'>
                  <Center />
                </div>
              </div>
            </div>
          </div>
      );
    else {
      return (
          <div className='App'>
            <p style={{fontSize: '14px', color: 'black'}}>Trying to connect the System...</p>
          </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    initialState: state.initialState,
  };
};

const mapDispachToProps = dispatch => {
  return {
    fetchUserToken: (info) => dispatch(info),
    fetchOrders: (info) => dispatch(info),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Dashboard);