import React from 'react';
import TableRow from './TableRow';
import {PaginatedList} from 'react-paginated-list'
import {Table, TableBody, TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const RowsTable = ({
       actionLastEditPageIndex,
       lastEditPageIndex,
       searchTerm,
       actionLastEditRowId,
       lastEditRowId,
       isExcelView,
       tableHeader,
       searchOrders,
       getRows,
       onChange,
       setIsEditRow,
       checked,
       fetchOrders,
       fetchDeleteOrder,
       fetchUpdateOrder,
       fetchSmsMessage,
       fetchGmailMessage,
       fetchWhatsappMessage,
       initialState,
   }) => {
    const items = getRows();

    if (isExcelView) {
        return (
            <TableContainer component={Paper}>
                <Table id="orders-table" aria-label="collapsible table">
                    {tableHeader}
                    <TableBody>
                        {items.map((item) =>
                            <TableRow
                                searchTerm={searchTerm}
                                actionLastEditRowId={actionLastEditRowId}
                                isOpen={item.id === lastEditRowId}
                                onChange={onChange}
                                searchOrders={searchOrders}
                                setIsEditRow={setIsEditRow}
                                isEditRow={checked}
                                fetchOrders={fetchOrders}
                                fetchDeleteOrder={fetchDeleteOrder}
                                fetchUpdateOrder={fetchUpdateOrder}
                                fetchSmsMessage={fetchSmsMessage}
                                fetchGmailMessage={fetchGmailMessage}
                                fetchWhatsappMessage={fetchWhatsappMessage}
                                initialState={initialState}
                                key={item.id}
                                row={item}
                                isExcel={initialState.excel_file}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    return (
        <PaginatedList
            list={items}
            itemsPerPage={50}
            onPageChange={(p, currentPage) => {
                actionLastEditPageIndex(currentPage)
            }}
            currentPage={lastEditPageIndex}
            renderList={(list) => {
                return (
                    <TableContainer component={Paper} style={{overflowX: 'initial'}}>
                        <Table id="orders-table" aria-label="collapsible table">
                            {tableHeader}
                            <TableBody>
                                {list.map(item => {
                                    return (
                                        <TableRow
                                            searchTerm={searchTerm}
                                            actionLastEditRowId={actionLastEditRowId}
                                            isOpen={item.id === lastEditRowId}
                                            onChange={onChange}
                                            searchOrders={searchOrders}
                                            setIsEditRow={setIsEditRow}
                                            isEditRow={checked}
                                            fetchOrders={fetchOrders}
                                            fetchDeleteOrder={fetchDeleteOrder}
                                            fetchUpdateOrder={fetchUpdateOrder}
                                            fetchSmsMessage={fetchSmsMessage}
                                            fetchGmailMessage={fetchGmailMessage}
                                            fetchWhatsappMessage={fetchWhatsappMessage}
                                            initialState={initialState}
                                            key={item.id}
                                            row={item}
                                            isExcel={initialState.excel_file}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }}
            controlItemClass="pagination-item"
            controlClass="pagination"
            activeControlClass="active"
            breakClass="pagination-break"
            leftMargin={6}
            rightMargin={6}
        />);
}

export default React.memo(RowsTable)