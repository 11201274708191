import primaryValues from '../constants/Values.js';

const initialState = {
  user_email: null,
  user_token: null,
  login_success: false,
  loading_login: false,
  token_success: false,
  error_msg: '',
  orders_success: false,
  orders: null,
  sendMessageModal: false,
  updateOrderModal: false,
  deleteOrderModal: false,
  newClubModal: false,
  newUserModal: false,
  newOrderModal: false,
  sendVaucherToCustomer: false,
  excel_file: false,
  newImageModal: false,
  isLogout: false,
  lastEditRowId: null,
  lastEditPageIndex: 1,
  updateSearchResults: false,
};

function updateGlobalOrders(state, action) {
  return state.orders.map(order => order.id === action.response.order.id ? action.response.order : order);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case primaryValues.$GET_LOGOUT:
      return {
        ...state,
        user_email: null,
        user_token: null,
        login_success: false,
        isLogout: true
      }
    case primaryValues.$GET_LOGIN:
      return {
        ...state,
        error_msg: '',
        loading_login: true,
        isLogout: false,
      }
    case primaryValues.$LOGIN_RESULT:
      return {
        ...state,
        user_email: action.response.user.email,
        user_token: action.response.user.remember_token,
        user_permission: action.response.user.permission,
        login_success: true,
        loading_login: false,
      }
    case primaryValues.$LOGIN_ERROR:
      return {
        ...state,
        error_msg: action.response.msg,
        login_success: false,
        loading_login: false,
      }
    case primaryValues.$TOKEN_RESULT:
      return {
        ...state,
        token_success: true,
      }
    case primaryValues.$TOKEN_ERROR:
      return {
        ...state,
        error_msg: action.response.msg,
        token_success: false,
      }
    case primaryValues.$GET_ORDERS:
      return {
        ...state,
        orders_success: false,
      }
    case primaryValues.$ORDERS_REFRESH:
      return {
        ...state,
        orders: action.orders,
        orders_success: false,
      }
    case primaryValues.$SUCCESS_LAST_ROW_EDIT_ID:
      return {
        ...state,
        lastEditRowId: action.id,
      }
    case primaryValues.$SUCCESS_EDIT_PAGE_INDEX:
      return {
        ...state,
        lastEditPageIndex: action.pageIndex,
      }
    case primaryValues.$ORDERS_RESULT:
      return {
        ...state,
        orders_success: true,
        orders: action.orders,
      }
    case primaryValues.$ORDERS_ERROR:
      return {
        ...state,
        orders_success: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$CLUBS_RESULT:
      return {
        ...state,
        clubs: action.response.clubs,
      }
    case primaryValues.$CLUBS_ERROR:
      return {
        ...state,
        error_msg: action.response.msg,
      }
    case primaryValues.$USERS_RESULT:
      const res = [];
      for (let i = 0; i < action.response.users.length; i++)
        if (action.response.users[i].name !== 'avishay' && action.response.users[i].name !== 'admin')
          res.push(action.response.users[i])

      return {
        ...state,
        users: res,
      }
    case 'MODALMSG':
      return {
        ...state,
        loading_login: false,
      }
    case primaryValues.$USERS_ERROR:
      return {
        ...state,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_GMAIL_MESSEAGE:
      return {
        ...state,
        updateSearchResults: false,
        sendMessageModal: true,
      }
    case primaryValues.$GMAIL_MESSEAGE_RESULT:
      return {
        ...state,
        orders: updateGlobalOrders(state, action),
        updateSearchResults: true,
        sendMessageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GMAIL_MESSEAGE_ERROR:
      return {
        ...state,
        sendMessageModal: false,
        updateSearchResults: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_WHATSAPP_MESSEAGE:
      return {
        ...state,
        updateSearchResults: false,
        sendMessageModal: true,
      }
    case primaryValues.$WHATSAPP_MESSEAGE_RESULT:
      return {
        ...state,
        orders: updateGlobalOrders(state, action),
        updateSearchResults: true,
        sendMessageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$WHATSAPP_MESSEAGE_ERROR:
      return {
        ...state,
        sendMessageModal: false,
        updateSearchResults: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_UPDATE_ORDER:
      return {
        ...state,
        updateSearchResults: false,
        updateOrderModal: true,
      }
    case primaryValues.$UPDATE_ORDER_RESULT:
      return {
        ...state,
        orders: updateGlobalOrders(state, action),
        updateOrderModal: false,
        updateSearchResults: true,
        error_msg: action.response.msg,
      }
    case primaryValues.$UPDATE_ORDER_ERROR:
      return {
        ...state,
        updateOrderModal: false,
        updateSearchResults: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_DELETE_CLUB:
      return {
        ...state,
        deleteOrderModal: true,
      }
    case primaryValues.$DELETE_CLUB_RESULT:
      return {
        ...state,
        deleteOrderModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$DELETE_CLUB_ERROR:
      return {
        ...state,
        deleteOrderModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_DELETE_USER:
      return {
        ...state,
        deleteOrderModal: true,
      }
    case primaryValues.$DELETE_USER_RESULT:
      return {
        ...state,
        deleteOrderModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$DELETE_USER_ERROR:
      return {
        ...state,
        deleteOrderModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_DELETE_ORDER:
      return {
        ...state,
        updateSearchResults: false,
        deleteOrderModal: true,
      }
    case primaryValues.$DELETE_ORDER_RESULT:
      return {
        ...state,
        orders: state.orders.filter( order => action.response.id !== order.id ),
        deleteOrderModal: false,
        updateSearchResults: true,
        error_msg: action.response.msg,
      }
    case primaryValues.$DELETE_ORDER_ERROR:
      return {
        ...state,
        deleteOrderModal: false,
        updateSearchResults: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_NEW_CLUB:
      return {
        ...state,
        newClubModal: true,
      }
    case primaryValues.$NEW_CLUB_RESULT:
      return {
        ...state,
        newClubModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$NEW_CLUB_ERROR:
      return {
        ...state,
        newClubModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_NEW_ORDER:
      return {
        ...state,
        updateSearchResults: false,
        newOrderModal: true,
      }
    case primaryValues.$NEW_ORDER_RESULT:
      return {
        ...state,
        orders: [action.response.order, ...state.orders],
        newOrderModal: false,
        updateSearchResults: action.response.order.id,
        error_msg: action.response.msg,
        lastEditRowId: action.response.order.id,
      }
    case primaryValues.$NEW_ORDER_ERROR:
      return {
        ...state,
        newOrderModal: false,
        updateSearchResults: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_NEW_USER:
      return {
        ...state,
        newUserModal: true,
      }
    case primaryValues.$NEW_USER_RESULT:
      return {
        ...state,
        newUserModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$NEW_USER_ERROR:
      return {
        ...state,
        newUserModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_EXCEL_FILE:
      return {
        ...state,
        excel_file: true,
      }
    case primaryValues.$SET_TABLE_VIEW:
      return {
        ...state,
        excel_file: false,
      }
    case primaryValues.$IMAGES_LIST_RESULT:
      return {
        ...state,
        images_list: action.data.images_list,
        images_names: action.data.images,
        url_selected_image: action.data.url_selected,
        url_selected_image_en: action.data.url_selected_en,
        url_selected_image_el: action.data.url_selected_el,
      }
    case primaryValues.$IMAGES_LIST_ERROR:
      return {
        ...state,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_NEW_IMAGE:
      return {
        ...state,
        newImageModal: true,
      }
    case primaryValues.$NEW_IMAGE_RESULT:
      return {
        ...state,
        newImageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$NEW_IMAGE_ERROR:
      return {
        ...state,
        newImageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_SMS_MESSAGE:
      return {
        ...state,
        sendMessageModal: true,
      }
    case primaryValues.$SMS_MESSAGE_RESULT:
      return {
        ...state,
        sendMessageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$SMS_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageModal: false,
        error_msg: action.response.msg,
      }
    case primaryValues.$GET_SEND_VAUCHER_CUSTOMER:
      return {
        ...state,
        sendVaucherToCustomer: true
      }
    case primaryValues.$SEND_VAUCHER_CUSTOMER_RESULT:
      return {
        ...state,
        sendVaucherToCustomer: false,
        error_msg: action.response.msg,
      }
    default:
      return state;
  }
};

export default reducer;