import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from "@material-ui/core/Collapse";
import TableInfo from './TableInfo.js';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function copyStringToClipboard(string) {
    function handler(event) {
        event.clipboardData.setData('text/plain', string);
        event.preventDefault();
        document.removeEventListener('copy', handler, true);
    }

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');
}

function Row(props) {
    const {
        actionLastEditRowId,
        isOpen,
        row,
        isExcel,
        initialState,
        fetchWhatsappMessage,
        close_modal,
        fetchSmsMessage,
        fetchGmailMessage,
        fetchUpdateOrder,
        fetchDeleteOrder,
        onChange,
        searchTerm,
        searchOrders,
        fetchOrders,
    } = props;
    const [open, setOpen] = React.useState(isOpen);
    const classes = useRowStyles();

    // console.debug('row.confirmed',row.confirmedValue)
    function handleClick(e) {
        e.preventDefault();
        if (e.type === 'click') {
            setOpen(!open)
            actionLastEditRowId(!(!open) ? '' : row.id)
        } else if (e.type === 'contextmenu') {
            copyStringToClipboard(e.target.textContent);
        }
    }

    return (
        <React.Fragment>
            <TableRow onContextMenu={handleClick} className={classes.root}
                      style={{backgroundColor: row.confirmedValue === 'Cancel' ? '#fcffca' : row.backColorType}}
                      onClick={handleClick}>
                <TableCell>
                    {isExcel ? null :
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    }
                </TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell
                    align="center">{row.date === '01/01/2030' || row.date === '01/01/1930' ? '' : row.date}</TableCell>
                <TableCell
                    align="center">{row.club.length > 30 ? row.club.substring(0, 30) + '...' : row.club}</TableCell>
                <TableCell align="right"
                           dir="rtl">{row.notes === `&nbsp;` ? `` : row.notes.length > 15 ? row.notes.substring(0, 15) + '...' : row.notes}</TableCell>
                <TableCell align="center">{row.people}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.mobile}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="right"
                           dir="rtl">{row.price.length > 15 ? row.price.substring(0, 15) + '...' : row.price}</TableCell>
                <TableCell align="center">{row.transfers}</TableCell>
                <TableCell align="center">{row.received_by}</TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center" style={{backgroundColor: row.backColorConfirmed}}>{row.confirmed}</TableCell>
                <TableCell align="center">{row.who_put}</TableCell>
                <TableCell align="center">{row.google}</TableCell>
                <TableCell align="center">{row.vip}</TableCell>
                <TableCell align="center">{row.vaucher}</TableCell>
                <TableCell align="center">{row.created_at}</TableCell>
            </TableRow>
            {isExcel ? null :
                <TableRow className={'oreder-table'} style={{backgroundColor: '#ebebeb'}}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb'}} colSpan={20}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1} style={{alignItems: 'center', minWidth: '100%'}}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Order Info {row.id}
                                </Typography>
                                <TableInfo searchTerm={searchTerm}
                                           onChange={onChange} searchOrders={searchOrders}
                                           fetchDeleteOrder={fetchDeleteOrder} fetchOrders={fetchOrders}
                                           fetchUpdateOrder={fetchUpdateOrder} fetchSmsMessage={fetchSmsMessage}
                                           fetchGmailMessage={fetchGmailMessage} close_modal={close_modal}
                                           fetchWhatsappMessage={fetchWhatsappMessage} initialState={initialState}
                                           setOpen={setOpen}
                                           row={row}/>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>}
        </React.Fragment>
    );
}

export default Row